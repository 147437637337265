@import "../../shared/main.less";

.error-containter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
}
.error-content {
  text-align: center;
  max-width: 500px;
  width: 100%;
}
.error-image{
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}
.error-title{
  font-family: @font_family_noto;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  color: @color_black;
  margin-bottom: 10px;
}
 .error-message {
  font-family: @font_family_noto;
  font-weight: 400;
  font-size: 16px;
  color: @color_black;
  margin-bottom: 30px;
 }
.home-button {
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
}
.support-email{
  font-family: @font_family_noto;
  font-size: 14px;
  color:@color_black;
  margin-top: 20px;

  a {
    color: @color_green;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
