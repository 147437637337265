@import "../../main.less";
@media only screen {
    @media (max-width: 800px) {
        .footerblock{
        display: block !important;
        text-align: center !important;
        }
    }
}
.footerblock {
    width: 100%;
    min-height: 30px;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    padding: 25px;
    border: 1px solid @color_border;
  }