@import "../../shared/main.less";
.cw-hub-weather-container {
    position: relative;
    width: 100%;
    height: 100vh;
  }
.cw-hub-weather-responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

.cw-hub-weather-error {
    margin: 25px;
    background: @color_white ;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}