.myfield-welcome-message {
  font-size: 18px;
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
}

.splash-login-btn {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #14803c;
  font-family: "Noto Sans", sans-serif;
  color: white;
  border: none;
  border-radius: 5px;
  font-weight: 400px;
  cursor: pointer;
  width: 100%;
}

.splash-signup {
  margin-top: 20px;
  font-size: 13px;
}

.splash-signup-link {
  color: #14803c;
}

.sign-in-modal {
  .syt-antd-modal-header {
    border-bottom: none !important;
  }
  .syt-antd-modal-footer {
    border-top: none !important;
  }
  .myfield-logo {
    width: 120px;
    height: 60px;
    margin-bottom: 20px;
  }
  .syt-antd-modal-title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
