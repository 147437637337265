@import "../../shared/main.less";

@media (min-width: 250px) and (max-width: 600px) {
  .precommitment-details-text {
    font-size: 12px !important;
  }
  .precommitments-body {
    margin: 5% !important;
  }
  .precommit-vocher-date {
    margin-left: 0px !important;
    margin-top: 7px !important;
  }
  .precommitment-create-date {
    display: block !important;
    margin-left: -8px !important;
    margin-top: 10px !important;
  }
  .precommit-pre-commit-flex {
    display: block !important;
  }
  .precommit-pre-icon {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .precommitment-grid-spam {
    margin-left: 0px !important;
    display: block !important;
  }
  .precommit-pre-commit {
    font-size: 14px !important;
    font-weight: 600 !important;
  }
  .precommit-create-date {
    display: block !important;
    margin-left: -8px !important;
  }

  .precommitments-create-button {
    margin-top: 20px !important;
  }
  .precommit-title {
    margin: 1px !important;
  }
  .precommitment-details-top-section {
    padding: 15px !important;
  }
  .precommitment-see-more {
    visibility: visible !important;
  }
  .precommit-title,
  .precommitment-details-top-section .precommitments-create-button {
    display: block !important;
  }
  .precommitment-details-content {
    width: auto !important;
    height: auto !important;
  }
  .precommitment-error-msg-body {
    width: auto !important;
    height: auto !important;
  }
  .precommitment-details-subcontent {
    width: 100% !important;
  }

}
.precommitment-grid-spam {
  margin-left: 25%;
  display: flex;
  align-items: center;
}
.precommit-pre-commit-flex {
  display: flex;
  justify-content: space-between;
}
.precommit-vocher-date {
  display: block;
  margin-left: 17%;
}
.precommit-title {
  margin: 10px;
  display: flex;
  justify-content: space-between;
}
.precommit-maintitle {
  font-family: @font_family_noto;
  font-size: 24px;
  font-weight: 700;
  line-height: 32.69px;
  text-align: left;
  color: @color_black;
}
.precommit-subtitle {
  font-family: @font_family_noto;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.07px;
  letter-spacing: 0.01em;
  text-align: left;
  color: @color_gray_light;
}
.precommitment-create-date {
  display: block;
  margin-left: 10%;
}
.precommitment-pre-commit {
  font-family: @font_family_noto;
  font-size: 16px;
  font-weight: 600;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  top: 3px;
  position: relative;
}
.precommitment-date {
  display: block;
  margin-left: 92px;
}
.precommitment-see-more {
  margin-top: 10px;
  margin-left: 30px;
  visibility: hidden;
}
.precommitment-gray-color {
  color: @color_gray_light;
}
.precommitment-bold {
  font-weight: 600;
}
.precommitment-icon {
  width: 24px;
  height: 24px;
  padding: 2.5px 2px 2.5px 2px;
}
.precommitment-margin-top {
  margin-top: 25px;
}
.precommitment-color-green {
  color: @color_green_light;
}
.precommitments-body {
  margin: 30px;
}
.precommitments-create-button {
  width: 300px;
  height: 40px;
}
.precommitment-details-content {
  width: 100%;
  height: auto;
  border-radius: 8px 8px 8px 8px;
  border: 0.96px 0px 0px 0px;
  background-color: @color_white;
  margin-top: 10px;
}
.precommitment-details-top-section {
  display: flex;
  justify-content: space-between;
  padding: 18px 18px 0px 18px;
}
.precommitment-details-subcontent {
  width: 100%;
  height: auto;
  border-radius: 3px 3px 8px 8px;
  background-color: #f3f3f3 !important;
  font-family: @font_family_noto;
  font-size: 11px;
  font-weight: 500;
  line-height: 14.98px;
  text-align: left;
  padding: 20px;
  border: 2px solid @color_white;
}
.precommitment-details-text {
  width: auto;
  text-wrap: nowrap;
  font-family: @font_family_noto;
  font-size: 14px;
  font-weight: 300;
  line-height: 14.98px;
  text-align: left;
}
.precommitment-error-msg-body {
  width: 100%;
  height: 90px;
  border-radius: 8px;
  background-color: @color_white;
  padding: 30px;
  margin-bottom: 30px;
  align-items: center;
  display: flex;
  justify-content: flex-start;
}
.precommit-error-div {
  display: flex;
}
.precommit-error-img {
  margin-right: 5px;
}
.precommitment-spam-msg {
  font-family: @font_family_noto;
  font-size: 14px;
  font-weight: 800;
  line-height: 20px;
  letter-spacing: -0.0075em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #14151c;
}
.precommitment-spam-comment {
  display: block;
  font-family: @font_family_noto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.0075em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #696f88;
}
.precommit-id {
  color: #464646;
  font-weight: 400;
  line-height: 19.07px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
