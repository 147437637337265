@import "../../shared/main.less";

.email-verification-msg {
  font-family: @font_family_noto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.0075em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  display: block;
  margin-top: 10px;
  color: @color_black;
}

.email-verification-outline-btn {
  margin-top: 10px;
  width: 290px;
  height: 35px;
  font-family: @font_family_noto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.0075em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.email-verification-icon {
  text-align: center;
}

.email-verification-image {
  text-align: center;
}

.email-verification-title {
  margin-top: 10px;
  color: @color_black;
  font-family: @font_family_noto;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.0075em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.email_verification_desc {
  margin-top: 10px;
  width: 415px;
  height: 60px;
  gap: 0px;
  display: inline-block;
  font-family: @font_family_noto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.0075em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.email-verification-popup {
  font-family: @font_family_noto;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.0075em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #14151c;
}
