@import "../../shared/main.less";


@media (max-width: 1200px){
  .loyalty-points-container {
    margin-left: 0px !important;
  }
}
.loyalty-points-container {
  margin-left: 30px;
  max-width: 100%;
  min-width: 91%;
  margin-bottom: 20px;
}
.loyalty-points-rows {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 5px;
  background-color: #f3f4f6;
  box-shadow: 0px 4px 0px 0px @color_green inset;
  padding: 0px 0px 10px !important;
  .syt-antd-card-body {
    padding: 0px !important;
  }
}

.loyalty-btn-view-more {
  color: @color_green;
  border: 0;
  border-radius: 4px;
  background: none;
}
.loyalty-btn-view-col {
  display: flex;
  justify-content: center;
}
.loyalty-card {
  border-radius: 8px;
  overflow: hidden;
  background-color: @color_white;
  box-shadow: 0px 4px 0px 0px @color_green inset;

  .loyalty-card-content {
    padding: 16px 16px 0;
  }

  .loyalty-card-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .loyalty-program-title {
    display: flex;
    align-items: center;
  }

  .loyalty-points {
    text-align: left;
    margin-top: 5px;
    text-wrap: nowrap;
    margin-right: 15px;
    .ant-typography {
      margin-bottom: 0;
    }
  }

  .loyalty-subtitle {
    display: block;
    margin-bottom: 16px;
    font-family: @font_family_noto;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: -0.75%;
  }
  .loyalty-your-points {
    font-family: @font_family_noto;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: -0.75%;
    color: #6a6a6a;
  }
  .loyalty-user-points {
    font-family: @font_family_noto;
    font-weight: 600;
    letter-spacing: 0%;
    color: #424242;
    text-wrap: nowrap;
  }
}
.tag-icon {
  width: 32px;
  height: 32px;
}
.loyalty-points-title {
  font-family: @font_family_noto;
  font-weight: 600;
  font-size: 16px;
  line-height: 21.79px;
  letter-spacing: 0%;
  color: @color_black;
  margin-bottom: 5px;
}
.loyalty-points-divider {
  height: 50px;
  margin-right: 25px;
}
