@import "../../shared/main.less";

.preference-page {
  .preferences-header {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    .preferences-header-left {
      flex: 100%;
    }
  }
  .preferences-container {
    background-color: @color_white;
    padding: 15px;
    border-radius: 7px;
    .preferences-container-title {
      font-family: @font_family_noto;
      font-weight: 600;
      font-size: 16px;
      line-height: 21.46px;
      margin-bottom: 5px;
    }
    .preferences-container-sub-title {
      font-size: 12px;
      font-weight: 400;
      color: #7d7d7d;
    }
    .syt-antd-divider {
      border-color: #f3f4f6 !important;
    }
    .all-switch-containers {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      @media only screen {
        @media (max-width: 992px) {
          .switch-container {
            margin-bottom: 10px;
          }
        }
      }
      .switch-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        .preference-icon {
          width: 20px;
          height: 20px;
        }
      }
    }
    .preferences-footer {
      .preferences-action-btn {
        width: 120px !important;
      }
    }
  }
}
