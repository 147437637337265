@import "../../../shared/main.less";

.auth-welcome-message-container {
  width: 383px;
  padding: 42px;
  border-radius: 16px;
  transition: 0.2s;
  position: absolute;
  bottom: 30px;
  right: 460px;
  background: #5c5c5c29;
  backdrop-filter: blur(6px);
  .auth-welcome-title {
    line-height: 40px;
    color: #fce500;
    font-weight: 700;
    font-size: 30px;
    margin-bottom: 12px;
  }
  .auth-welcome-desc {
    line-height: 28px;
    color: @color_white;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0px;
  }
}

@media (max-width: 1420px) {
  .auth-welcome-message-container {
    right: 290px;
  }
}

@media (max-width: 1250px) {
  .auth-welcome-message-container {
    right: 235px;
  }
}

@media (max-width: 1190px) {
  .auth-welcome-message-container {
    right: 140px;
  }
}

@media (max-width: 1084px) {
  .auth-welcome-message-container {
    display: none !important;
  }
}
