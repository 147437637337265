.landing-screen-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  margin: 0;
}

.landing-screen-content {
  max-width: 400px;
  text-align: center;
  margin: auto;
}

.syn-field-logo {
  width: 188px;
  height: 70px;
  margin-bottom: 60px;
}

.syn-field-support {
  margin-top: auto;
  margin-bottom: 20px;
  text-align: center;
}

.syn-support-logo {
  width: 50px;
  height: 50px;
  margin-top: 20px;
}
