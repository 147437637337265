@import "../../shared/main.less";

.manage-fields-container {
  background-color: #ffffff;
  padding: 32px;
  border-radius: 8px;
  .field-loader-skelton {
    width: 100% !important;
    .anticon {
      display: none;
    }
  }
  .manage-field-sub-title {
    font-family: @font_family_noto;
    font-weight: 700;
    font-size: 18px;
  }
  .manage-field-name {
    font-size: 14px;
    font-weight: 600;
  }
  .emergence-date-label {
    color: #12121299;
    font-size: 12px;
    font-family: @font_family_noto;
  }
  .manage-field-title-desc {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #12121299;
    font-family: @font_family_noto;
  }
  .no-field-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    .no-field-created-sec {
      display: flex;
      flex-direction: column;
      align-items: center;
      .error-title {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        color: @color_black;
        font-family: @font_family_noto;
        margin-bottom: 0px;
      }
      .error-desc {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: #12121299;
        font-family: @font_family_noto;
      }
    }
  }
  .field-detail-container {
    border: 1px solid #d7d7d7;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    .field-edit-section-title {
      font-size: 12px;
      font-weight: 600;
      font-family: @font_family_noto;
      color: @color_black;
    }
    .field-name-container {
      flex: 2;
    }
    .field-edit-container {
      flex: 7;
    }
    .field-edit-section {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
    }
    .date-picker-label {
      font-size: 12px;
      font-family: @font_family_noto;
      color: @color_black;
    }
    .field-action-container {
      flex: 1;
      display: flex;
      align-items: flex-end;
    }
  }
  @media only screen {
    @media (max-width: 768px) {
      .field-detail-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }
    @media (max-width: 576px) {
      .field-detail-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .field-edit-section {
          flex-direction: column;
        }
      }
    }
  }
}

.crop-filter-list {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}

.delete-field-confirm-modal {
  .syt-antd-modal-content {
    .syt-antd-modal-header {
      border: none !important;
      padding: 24px 24px 0px !important;
      .new-error-modal-container {
        display: flex;
        gap: 18px;
      }
      .error-modal-header-message {
        color: #14151c;
        font-size: 16px;
        font-weight: 600;
      }
    }
    .syt-antd-modal-footer {
      .syt-antd-btn syt-antd-btn-default {
        color: #14151c !important;
        border-color: #c2c7d0 !important;
      }
      .syt-antd-btn-dangerous {
        border-color: #cf3537 !important;
        background: #cf3537 !important;
        color: @color_white !important;
      }
      border: none !important;
    }
  }
}
