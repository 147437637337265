.vevpage-container {
    position: relative;
    width: 100%;
    height: 100vh;
    padding-top: 100%; 
  }
.vev-page-responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }