@import "../../shared/main.less";

@media only screen {
  @media (min-width: 300), (max-width: 700px) {
    .recommendation_body {
      width: 370px !important;
      height: 145px !important;
      display: block !important;
    }
    .myrecommendations_title {
      display: block !important;
    }
    .createrecommendation_button {
      margin-top: 10px !important;
    }

    .recommendation_viewfull {
      margin-top: 10px !important;
    }
  }
}
.myrecommendations_title {
  margin: 30px;
  display: flex;
  justify-content: space-between;
}
.myrecommendations_maintitle {
  font-family: @font_family_noto;
  font-size: 24px;
  font-weight: 700;
  line-height: 32.69px;
  text-align: left;
  color: @color_black;
}
.myrecommendations_subtitle {
  font-family: @font_family_noto;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.07px;
  letter-spacing: 0.01em;
  text-align: left;
  color: @color_gray_light;
}
.createrecommendation_button {
  color: @color_green_light;
  width: 327px;
  height: 55px;
  border-radius: 16.96px;
  border: 1px solid @color_green_light;
  font-weight: 600;
}
.recommendation_body {
  width: 950px;
  height: 90px;
  border-radius: 8px;
  background-color: @color_white;
  margin: 30px;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.recommendation_name {
  font-family: @font_family_noto;
  font-size: 24px;
  font-weight: 700;
  line-height: 32.69px;
  text-align: left;
  color: @color_black;
}
.recommendation_viewfull {
  width: auto;
  height: 45px;
  border-radius: 8px;
  background-color: @color_green;
  color: @color_white;
}
.recommendation_bold {
  font-weight: 900;
}
