@import "../../main.less";

.link-expired-button {
  width: 180px;
  height: 30px;
  font-family: @font_family_noto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.0075em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.link-expired-myfield-logo {
  margin: 10px 0px 10px 0px;
  height: 157px;
  width: 140px;
}
.link-expired-success-container {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: @color_white;
  overflow: scroll;
  height: 100vh;
}
.link-expired-success-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  text-align: center;
  max-width: 450px;
  height: 95%;
}
.link-expired-msg {
  font-family: @font_family_noto;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.0075em;
  text-align: center;
  color: #14151c;
  font-style: normal;
  margin-top: 10px;
}
.link-expired-image-style {
  width: 378px;
  height: 163px;
  gap: 0px;
  border: 0.83px 0px 0px 0px;
}

.link-expired-desc-msg {
  font-family: @font_family_noto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.0075em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
