@import "../../shared/main.less";

.email-success-container {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: @color_white;
  overflow: scroll;
  height: 100vh;
}
.email-success-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  text-align: center;
  max-width: 450px;
  height: 95%;
  .lng-selection-dropdown {
    align-self: flex-end;
    border: none;
    & li.syt-antd-menu-submenu {
      &.syt-antd-menu-submenu-selected::after {
        border: none;
      }
    }
  }
  .syt-antd-menu-horizontal {
    line-height: 21px;
  }
}

.email-success-enter-button {
  width: 349px;
  height: 40px;
  border-radius: 4px 4px 4px 4px;
  color: @color_white;
}
