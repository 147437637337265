@import "../../shared/main.less";

@media (min-width: 768px) {
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (min-width: 768px) {
  .col-md-6 {
    width: calc(50% - 20px);
    float: left;
  }
}

@media (min-width: 601px) and (max-width: 2500px) {
  .mobsection {
    display: none;
  }
}
@media (min-width: 250px) and (max-width: 600px) {
  .recommendation-field-btn,
  .wereto,
  .Gettechnical {
    width: 100%;
  }
  .commongib {
    width: 100%;
  }
  .container {
    flex-direction: row;
    margin-right: 30px;
  }
  .col-md-6 {
    margin-right: 20px;
  }
  .col-md-12 {
    margin-right: 20px;
  }
}

.summaryinsightsroot {
  margin-top: 30px;
  margin-left: 30px;
}
.summaryinsightstitle {
  font-family: @font_family_noto;
  font-size: 24px;
  font-weight: 700;
  line-height: 32.69px;
  text-align: left;
  color: #27262e;
}
.summarytechnicaltitle {
  font-family: Manrope, @font_family_noto;
  font-size: 20px;
  font-weight: 800;
  line-height: 28px;
  text-align: left;
  margin-top: 40px;
}
.summarytechincalreport {
  margin-top: 20px;
  width: 527px;
  height: 213px;
  border-radius: 8px;
  background: @color_white;
}
.summarytechincalbutton {
  width: 465px;
  height: 49px;
  border-radius: 8px;
  margin-top: 80px;
  margin-left: 30px;
}

.top-boxsty {
  width: 98%;
  position: relative;
}
.headingpagecontent h2 {
  font-size: 30px;
  line-height: 30px;
  font-weight: bolder;
}
.headingpagecontent {
  margin-top: 20px;
}
.pagesty p {
  color: #12121299;
}
.headingpagecontent p {
  margin-top: 10px;
  color: #12121299;
  text-align: justify;
  margin-right: 35px;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

/* Row */
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col-md-12 {
  flex: 0 0 100%;
  max-width: 100%;
}


.line {
  border: none;
  border-top: 1px solid #eae0e0;
  margin: 30px 0px 30px 0px;
  width: 98%;
}
.wereto {
  border: none;
  background-color: #36bc4c;
  color: #ffffff;
  border-radius: 8px;
  padding: 15px 80px 15px 80px;
  font-weight: 900;
}

.recommendation-field-btn {
  border: none;
  background-color: #36bc4c;
  color: #ffffff;
  border-radius: 8px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 15px 80px 15px 80px;
  font-weight: 900;
}
.commongib {
  background-color: #e8e8e8;
  padding: 30px;
  width: 300px;
}

.headingpagecontent h6 {
  font-size: 15px;
  font-weight: bolder;
}
.conatctdatails p {
  font-size: 20px;
  font-weight: bold;
}

.col-md-6 {
  width: 98%;
}

.headingpagecontent b {
  font-size: 15px;
  font-weight: bolder;
}


.Gettechnical {
  background: #212121;
  color: #fff;
  padding: 15px 40px 15px 40px;
  border: none;
  border-radius: 5px;
}
.spassty {
  margin-bottom: 30px;
}
.conatctdatails p span {
  font-size: 15px;
  font-weight: bolder !important;
  padding-left: 15px;
}

.mobibg {
  background: #e8e8e8;
  padding: 25px;
}
.producticon {
  position: absolute;
  width: 100%;
  height: 100%;
}
button {
  cursor: pointer;
}
