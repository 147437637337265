@import "../../../core/config/styles/theme.less";
@import "../../main.less";
@media only screen and (min-width: 700px) and (max-width: 991px) {
  .get-now-banner {
    min-height: 140px !important;
  }
  .get-now-icon {
    width: 50% !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .get-now-icon {
    width: 50% !important;
  }
}
@media only screen and (min-width: 992px) {
  .syt-antd-layout-sider-collapsed {
    max-width: 64px !important;
  }
}

@media only screen and (max-width: 992px) {
  .syt-antd-layout-sider-collapsed {
    max-width: 40px !important;

    .sidebarFooter {
      padding-left: 4px !important;
    }
    .syt-antd-layout-sider-trigger {
      width: 40px !important;
    }
  }
  .sidebar_collapse {
    width: 40px !important;
  }

  .sidebar-icon-style {
    width: 40px !important;
  }
  .collapse-menu-margin {
    margin-left: 0px !important;
  }
}
.syt-antd-menu-item.svg:active {
  background: none;
}
.syt-antd-menu-item {
  cursor: context-menu !important;
  padding: 12px 4px 12px 20px !important;
}
.side-menu-item-active {
  color: @color_green !important;
  background-color: #dffbe8 !important;
}

.sidebar-selected-background {
  background: #dffbe8 !important;
}

.side-menu-selected {
  position: absolute;
  width: 4px;
  height: 20px;
  left: -22px;
  gap: 0px;
  border-radius: 0px 4px 4px 0px;
  background: @color_green_light;
}

.side-menu-location img {
  height: 20px;
}

.side-menu-user {
  margin-top: 22px;
  margin-bottom: -40px;
  display: flex;
  justify-content: space-between;
}

.side-menu-points {
  color: #848484;
}

.side-menu-username {
  font-weight: 600;
}

.side-menu-notification-number {
  position: relative;
  top: -10px;
  right: 10px;
  padding: 0px 5px;
  border-radius: 50%;
  background-color: #c10000;
  color: @color_white;
}

.banner-button-white {
  background: transparent !important;
  border: none !important;
  padding: 0 !important;
  margin-bottom: 5px;
}
.side-menu-user-name {
  font-family: @font_family_noto;
  font-size: 23px;
  font-weight: 800 !important;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
}

.side-menu-banner-li {
  list-style-type: none;
  margin-top: 5px;
  font-weight: 100;
}

.side-menu-banner-image {
  position: absolute;
  margin-left: 120px;
  margin-top: -88px;
}

.side-menu-label {
  font-family: @font_family_noto;
  font-size: 11px;
  font-weight: 900;
  line-height: 16px;
  letter-spacing: 0.14em;
  text-align: left;
  width: 77px;
  height: 16px;
  margin-top: 20px;
  cursor: context-menu;
  position: relative;
  left: -10px !important;
}

.side-menu-text {
  font-family: "Noto Sans", sans-serif;
  font-size: 12px;
  line-height: 21px;
  letter-spacing: 0em;
  font-weight: 600;
}

.side-menu-items-icon {
  cursor: pointer;
  position: relative;
  width: 24px;
  height: 24px;
  right: 10px;
  color: #7a797d;
}

.side-menu-items {
  position: relative;
  top: 40px;
  left: 2px;
  gap: 16px;
}

.side-submenu-item {
  position: relative;
  margin-top: 15px;
}

.side-submenu-icon-size {
  width: 20px;
  height: 20px;
}

.side-submenu-item-icon-left {
  left: -10px !important;
}

.@{ant-prefix}-menu-item {
  padding-left: 24px !important;
}

.@{ant-prefix}-menu-item-selected::before {
  content: " ";
  color: @color_white;
  position: absolute;
  width: 4px;
  left: 0px;
  top: 4px;
  bottom: 4px;
  background: @color_white;
  border-radius: 0px 4px 4px 0px;
}

.syt-antd-tooltip-inner {
  display: none;
}

.syngenta-ant-layout-sider,
.syngenta-ant-menu.syngenta-ant-menu-dark {
  background-color: @color_white !important;
}

.syt-antd-layout-sider-trigger {
  color: white !important;
  position: sticky !important;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 280px;
  padding: 10px;
}

.syt-antd-layout-sider-collapsed {
  .syt-antd-layout-sider-trigger {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
}

.sidebar_points {
  cursor: pointer;
}

.sidebarFooter {
  bottom: 60px !important;
}

.sidebar_button {
  border: none;
  outline: none;
  background: none;
}

.sidebar_button_left {
  left: 2px !important;
}

.sidebar_margin_left_4 {
  margin-left: 4px;
}

.sidebar_left {
  left: -3px !important;
}

.sidebar_margin_left_5 {
  margin-left: 5px;
}

.sidebar_margin_left_5 button:hover {
  color: @color_black;
}
.sidebar_sub_menu_text_color {
  color: #696F88 !important;
}
.sidebar_collapse {
  width: 64px;
  bottom: 0;
  gap: 0px;
  background-color: @color_white;
  z-index: 123899789088 !important;
}

.sidebar-icon-style {
  width: 60px;
  height: 25px;
  margin-top: 25px;
  cursor: pointer;
}

.sidebar-collapse-selected-item {
  position: absolute;
  width: 4px;
  height: 20px;
  margin-top: 2px;
  gap: 0px;
  border-radius: 0px 4px 4px 0px;
  background: @color_green_light;
}

.collapse-submenu-item {
  visibility: hidden;
  width: max-content;
  border-radius: 5px;
  position: absolute;
  left: 75px;
  margin-top: -30px;
  z-index: 100;
  background: @color_white;
  padding: 10px;
}

.collapse-submenu-submenus {
  left: 45px !important;
}

.collapse-submenu-divider {
  border-top: 1px solid @color_gray_light;
}

.sidebar-icon-style:hover {
  .collapse-submenu-item {
    visibility: visible;
  }
}
.collapse-submenu-item:visited {
  visibility: visible;
}

.sidebar-icon-styel:active {
  .collapse-submenu-item {
    visibility: visible;
    color: @color_green_light;
  }
}

.popmenu.collapse-submenu-item::before {
  content: "";
  position: absolute;
  top: 45%;
  margin-left: -19px;
  border-width: 5px;
  border-style: solid;
  border-color: #ffffff transparent transparent transparent;
  transform: rotate(90deg);
  z-index: 12345;
}
.collapse-menu-hover {
  margin-left: 10px;
}
.collapse-menu-hover:hover {
  color: @color_green;
}
.collapse-submenu-selected-background {
  background: #dffbe8 !important;
  color: @color_green;
  width: 100%;
}
.collapse-menu-margin {
  margin-left: 15px;
}

.get-now-menu-items {
  position: relative;
  top: 40px;
  left: -15px;
  width: 270px;
  height: auto;
}
.get-now-buttons {
  display: flex;
  flex-direction: column;
}

.get-now-banner {
  width: 100%;
  min-height: 120px;
  position: relative;
  border-radius: 9px;
  text-align: center;
  cursor: context-menu;
  background-image: url("../../../assets/icons/get-more.svg");
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f8f8f8;
  background-position: center;
  background-repeat: no-repeat; 
  background-size: cover; 
}
.get-now-icon{
  width: 100%;
}