@import "../../shared/main.less";

@media only screen {
  @media (max-width: 992px) {
    .new-password-form {
      width: 90% !important;
    }
  }
  @media (max-width: 850px) {
    .new-password-image {
      width: 0px !important;
    }
    .new-password-form {
      width: 70% !important;
    }
    .new-password-sec {
      width: 100% !important;
    }
    .new-password-container {
      max-width: none !important;
      left: 0px !important;
      top: 0px !important;
      bottom: 0px !important;
      border-radius: 0px !important;
    }
  }
  @media (min-width: 850px) and (max-width: 1084px) {
    .new-password-container {
      left: 23% !important;
    }
  }
}
.change-passwerd-error {
  border: 1px solid @color_red !important;
}
.new-password-field-logo {
  margin: 10px 0px 10px 0px;
  height: 157px;
  width: 140px;
}
.new-password-sec {
  background: #ffffff;
  width: 50%;
}
.new-password-container {
  background-color: @color_white;
  max-width: 460px;
  width: 100%;
  border-radius: 16px;
  position: absolute;
  top: 40px;
  bottom: 30px;
  left: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.2s;
}

.new-password-form {
  width: 80%;
  display: flex;
  flex-direction: column;
}
.new-password-image {
  left: 0px;
  top: 0px;
  width: 50%;
  height: 100vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  position: relative;
}
.new-password-msg {
  color: #14151c;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 20px;
  text-align: center;
  font-family: @font_family_noto;
  max-width: 316px;
}
.new-password-label {
  color: #14151c;
  font-family: @font_family_noto;
  font-size: 10px;
  margin-bottom: 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.new-password-desc {
  font-family: @font_family_noto;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.0075em;
  text-align: center;
  color: #14151c;
  font-style: normal;
  margin-bottom: 10px;
}

.new-password-buttons {
  display: flex;
  justify-content: space-evenly;
  margin-top: 15px;
  .change-password-btns {
    width: 50%;
    margin: 5px;
    font-size: 12px;
  }
}
