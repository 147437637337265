@import "../../main.less";

.mf-pagination{
.syt-antd-pagination .syt-antd-pagination-item-active{
    background-color: @color_green ;
    border: @color_green;
}

.syt-antd-pagination .syt-antd-pagination-item-link:hover a, .syt-antd-pagination .syt-antd-pagination-item:hover a, .syt-antd-pagination .syt-antd-pagination  a {
    color: @color_green !important;
}
.syt-antd-pagination .syt-antd-pagination-item-link:hover, .syt-antd-pagination .syt-antd-pagination-item:hover, .syt-antd-pagination .syt-antd-pagination-item-link:active, .syt-antd-pagination .syt-antd-pagination-item:active {
    border: 1px solid @color_green !important;
}

.syt-antd-pagination .syt-antd-pagination-item-link:focus, .syt-antd-pagination .syt-antd-pagination-item:focus {
    border: 1px solid @color_green !important;
}
.syt-antd-pagination .syt-antd-pagination-item-link, .syt-antd-pagination .syt-antd-pagination-item:focus{
    color: @color_green !important;
}
 .syt-antd-pagination-item-link:hover a, .syt-antd-pagination .syt-antd-pagination-item:hover, .syt-antd-pagination .syt-antd-pagination, .syt-antd-pagination-item-active:hover a:hover{
    color: @color_white !important;
}

}