@import "../../core/config/styles/theme.less";

.error-boundary {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
  }
  
  .error-boundary-label {
    display: inline-block;
    margin-bottom: 0px;
    font-weight: 500;
    font-size: 46px;
    color: rgb(46, 51, 125);
  }

  .main-layout {
    overflow-y: auto;
    .layout-container {
      background: @--neutral-neutral-00;
      box-shadow: 0 2px 8px @--light-gray-15;
      border-radius: 10px !important;
      height: 100%;
      overflow: hidden;
      .card-wrapper {
        padding: 16px;
        overflow-y: auto;
        height: 100%;
        .@{ant-prefix}-card {
          border: none;
          &:focus {
            background: @--blue-blue-10;
          }
          .@{ant-prefix}-card-body {
            margin-top: 20px;
            padding: 0;
            .@{ant-prefix}-row {
              flex-wrap: nowrap;
              padding: 12px;
            }
          }
          &:nth-child(1) {
            .@{ant-prefix}-card-body {
              margin-top: 0;
            }
          }
        }
      }
    }
  }