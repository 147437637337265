@import "../../../shared/main.less";

.banner-container {
  background-color: @color_white;
  border-radius: 8.35px;
  min-height: 220px;
  max-height: 220px;
  min-width: 100%;
  .banner-image {
    img {
      width: 100%;
      height: 82px;
      border-radius: 8.35px;
    }
  }
  .banner-info-sec {
    padding: 15px;
    .banner-info-title {
      padding-top: 10px;
      font-size: 17px;
      font-weight: 500;
      line-height: 20px;
      color: #232630;
      min-height: 50px;
    }
    .banner-info-btn {
      width: 100%;
      background-color: #00004b;
      border-radius: 4px;
      height: 40px;
    }
  }
}

.banner-title {
  font-family: @font_family_noto;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  color: #232630;
  display: flex;
  align-items: center;
  .banner-title-icons {
    width: 32px;
    height: 32px;
  }
}

.banner-text {
  font-family: @font_family_noto;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #ffffff;
}

.banner-desc {
  font-family: @font_family_noto;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  color: #232630;
}

.card-box {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  img {
    border-radius: 10px;
    max-width: 280px;
    max-height: 153px;
  }
}

.banner-with-background-color-img {
  width: 100%;
  height: 130px;
  background-size: cover;
  background-position: center;
  flex-direction: column;
  justify-content: center;
}

.box-with-background {
  justify-content: space-between;
}

.banner-column {
  width: 100%;
}

.banner-button {
  font-family: "Manrope", sans-serif;
  border-radius: 8px;
  font-weight: 500;
  font-size: 12px;
  background-color: @color_green;
}

.banner-btn-align-left {
  justify-content: end;
  align-items: start;
}

.banner-btn-align-right {
  justify-content: end;
  align-items: end;
}
.banner-btn-title-align {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}
@media screen and (max-width: 767px) {
  .banner-column {
    margin-right: 0;
    padding-right: 0;
  }
}
