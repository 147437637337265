@import "../../../shared/main.less";

.forgot-password-footer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
  padding: 0px 10px 0px 30px;
  .forgot-password-terms-btn {
    color: @color_green;
    align-self: flex-end;
  }
  .lng-selection-dropdown {
    align-self: flex-end;
    border: none;
    & li.syt-antd-menu-submenu {
      &.syt-antd-menu-submenu-selected::after {
        border: none;
      }
    }
  }
  .syt-antd-menu-horizontal {
    line-height: 0px;
  }
}