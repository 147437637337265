@import "../../shared/main.less";

.email-expired-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: @color_white;
  overflow: scroll;
}
.email-support-color {
  color: @color_green;
}
.email-hr {
  border: solid 1px #dfe2e7;
  width: 96%;
}
.email-expired-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  text-align: center;
  max-width: 400px;
  height: 95%;
  .lng-selection-dropdown {
    align-self: flex-end;
    border: none;
    & li.syt-antd-menu-submenu {
      &.syt-antd-menu-submenu-selected::after {
        border: none;
      }
    }
   
  }
  .syt-antd-menu-horizontal{
    line-height: 21px;
  }
}
.email-expired-title {
  font-family: @font_family_noto;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.0075em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.email-expired-msg {
  font-family: @font_family_noto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.0075em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 1rem;
  color: #14151C;

}
.logo {
  color: #1e3c72;
  margin-bottom: 1rem;
}
.email-myfiled-icon {
  height: 157px;
  width: 140px;
}
.email-illustration-img {
  margin-bottom: 0.5rem;
  width: 392px;
  height: 150px;
}

.form-group {
  margin-bottom: 1rem;
  text-align: left;
}

.email-label {
font-family: @font_family_noto;
font-size: 14px;
font-weight: 400;
line-height: 20px;
letter-spacing: -0.0075em;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #14151C;
}

.email-input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.resend-button {
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 1rem;
  padding: 7px;
}

.enter-button {
  border-radius: 4px;
  width: 100%;
  margin-bottom: 1rem;
  color: @color_green;
  padding: 7px;
}

.email-footer {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-right: 1px;
  margin-left: 25px;
}
.email-support {
  font-family: @font_family_noto;
  font-size: 14px;
  color: #363948;
  margin-top: 10px;
}
.email-terms-btn {
  color: @color_green;
  align-self: flex-end;
}
